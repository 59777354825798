import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

export const updateSponsor = /* GraphQL */ `
  mutation UpdateSponsor(
    $input: UpdateSponsorInput!
    $condition: ModelSponsorConditionInput
  ) {
    updateSponsor(input: $input, condition: $condition) {
      id
    }
  }
`;

const getSponsor = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      therapeuticArea
      requiredDocumentsIds
      sponsorStaff {
        id
        closingDate
      }
      sponsorList {
        id
        closingDate
        code
        agency {
          id
        }
      }
      publicPage {
        id
        buyOperation {
          id
          isExpired
          isDeleted
        }
      }
      sponsorVirtualStand {
        items {
          id
          virtualRoomLink
          virtualStand {
            id
            name
            ranking
            isEnabled
            specificationPost
          }
          sponsorPackageItem {
            package {
              buyOperations {
                items {
                  id
                  purchase {
                    id
                    paymentType
                    payment {
                      paymentType
                      paymentIdMonetaOnLine
                      paymentIdIngenico
                      paymentIdGpWebpay
                    }
                  }
                }
              }
            }
          }
          buyOperation {
            id
            isExpired
            isDeleted
            purchase {
              id
              purchaseId
              paymentType
              payment {
                paymentType
                paymentId
                paymentIdMonetaOnLine
                shasignIngenico
                paymentIdGpWebpay
              }
            }
          }
        }
      }
      buyOperations(limit: 400) {
        items {
          id
          isExpired
          isDeleted
          symposium {
            id
          }
          bannerArea {
            id
          }
          physicalStandSpace {
            id
          }
          physicalStandSpaceItems {
            id
          }
          publicPage {
            id
          }
          breakoutRoom {
            id
          }
          sponsorVirtualStand {
            id
          }
          sponsorStaffPurchase {
            id
          }
          sponsorListPurchase {
            id
          }
          sponsorListPurchase {
            id
          }
          symposiumServicesPurchase {
            id
          }
          otherSponsorizationsBookingItem {
            id
          }
          sponsorPackage {
            id
            services {
              items {
                physicalStand {
                  id
                }
                publicPageService {
                  id
                }
                symposium {
                  id
                }
                symposiumServicesPurchase {
                  id
                }
                virtualStand {
                  id
                }
                breakoutRoomService {
                  id
                }
                bannerArea {
                  id
                }
              }
            }
          }
          purchase {
            id
            purchaseId
            paymentType
            payment {
              paymentType
              paymentId
              paymentIdMonetaOnLine
              shasignIngenico
              paymentIdGpWebpay
            }
          }
        }
      }
      priceRange {
        id
        start
        description
        name
        price
      }
      event {
        sponsorDeactivationDate
        bannerService {
          id
          publicationDate
          deactivationDate
        }
        symposiumService {
          id
          publicationDate
          deactivationDate
        }
        publicPageService {
          id
          publicationDate
          deactivationDate
        }
        breakoutRoomService {
          id
          publicationDate
          deactivationDate
        }
        physicalStandService {
          id
          publicationDate
          deactivationDate
        }
        otherStandServicesService {
          id
          publicationDate
          deactivationDate
        }
        virtualStandService {
          id
          publicationDate
          deactivationDate
        }
        sponsorStaffService {
          id
          publicationDate
          deactivationDate
        }
        sponsorListService {
          id
          publicationDate
          deactivationDate
        }
        symposiumServicesService {
          id
          publicationDate
          deactivationDate
        }
        otherSponsorizationsService {
          id
          publicationDate
          deactivationDate
        }
      }
      breakoutRooms {
        items {
          id
          date
          start1
          start2
          end1
          end2
          duration
          maxAttendants
          purchasedHours
          sponsor {
            id
            name
          }
          sponsorPackageItem {
            package {
              buyOperations {
                items {
                  id
                  purchase {
                    id
                    date
                    paymentType
                    payment {
                      paymentType
                      paymentIdMonetaOnLine
                      paymentIdIngenico
                      paymentIdGpWebpay
                    }
                  }
                }
              }
            }
          }
          buyOperation {
            id
            date
            unitaryPrice
            unitaryVatRate
            purchase {
              id
              date
              paymentType
              payment {
                paymentIdMonetaOnLine
                paymentIdIngenico
                paymentIdGpWebpay
                paymentType
              }
            }
          }
          slots {
            items {
              id
              title
              start
              end
              note
              emails {
                items {
                  email
                  id
                  note
                  slot {
                    id
                  }
                  state
                  token
                }
              }
              isOneToOne
              invitations {
                items {
                  id
                  participation {
                    id
                    isDeleted
                    cognitoUserId
                    identityPoolIdentityId
                    cluster
                    givenName
                    familyName
                    email
                    businessName
                    phone
                    fieldValues {
                      items {
                        id
                        value
                        fieldDefinition {
                          id
                          controlType
                        }
                      }
                    }
                    participationGrant(filter: { isDeleted: { ne: true } }) {
                      items {
                        id
                        participationGrantGrantId
                      }
                    }
                    profile {
                      id
                      name
                      cluster
                      isPublic
                      createdAt
                      updatedAt
                    }
                    userShowcase {
                      id
                      contactEmail
                      profileImage {
                        id
                        extension
                        originalName
                        size
                      }
                    }
                    userSettings {
                      isPublic
                    }
                    myAgenda {
                      items {
                        id
                        session {
                          id
                        }
                      }
                    }
                    myBreakoutRoom(filter: { state: { eq: "accepted" } }) {
                      items {
                        id
                        isSelfProposed
                        note
                        state
                        slot {
                          id
                          start
                          end
                        }
                      }
                    }
                    surveyInvitations {
                      items {
                        id
                        isCompleted
                        survey {
                          id
                        }
                      }
                    }
                    agency {
                      id
                    }
                    sponsorStaff {
                      id
                      sponsor {
                        id
                        isDeleted
                        publicPage {
                          id
                          isPublic
                          buyOperation {
                            id
                            purchase {
                              id
                            }
                          }
                        }
                        priceRange {
                          id
                          name
                          price
                          start
                        }
                        fieldValues {
                          items {
                            id
                          }
                        }
                      }
                    }
                    event {
                      id
                      name
                      abstractService {
                        id
                        maxAbstractsPerAuthor
                        abstractTerm
                      }
                    }
                    guests {
                      items {
                        id
                        givenName
                        familyName
                        country
                      }
                    }
                    sponsor {
                      id
                      isDeleted
                      publicPage {
                        id
                        isPublic
                        sponsorPackageItem {
                          package {
                            buyOperations {
                              items {
                                id
                                purchase {
                                  id
                                }
                              }
                            }
                          }
                        }
                        buyOperation {
                          id
                          purchase {
                            id
                          }
                        }
                      }
                      priceRange {
                        id
                        name
                        price
                        start
                      }
                      fieldValues {
                        items {
                          id
                        }
                      }
                    }
                    isReviewer
                    isSpeaker
                    reviewer {
                      id
                      reviews {
                        items {
                          id
                        }
                      }
                    }
                  }
                  state
                  note
                  isSelfProposed
                }
              }
            }
          }
        }
      }
    }
  }
`;

const getDeadlineQuery = /* GraphQL */ `
  query GetDeadline($externalId: ID!, $type: String!) {
    getDeadline(externalId: $externalId, type: $type) {
      externalId
      type
      value
    }
  }
`;

export const useSponsors = () => {
  const update = (input) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: updateSponsor, variables: { input } })
        .then((response) => resolve(response.data.updateSponsor))
        .catch((e) => {
          console.error('update-sponsor', e);
          reject();
        })
        .finally(hideLoader);
    });

  const get = (id) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({ query: getSponsor, variables: { id: id } })
        .then((response) => {
          const nextSponsor = {
            ...response.data.getSponsor,
            requiredDocumentsIds:
              response.data.getSponsor?.requiredDocumentsIds || [],
            sponsorVirtualStand: {
              items: response.data.getSponsor?.sponsorVirtualStand?.items.map(
                (item) => ({
                  ...item,
                  buyOperation: item?.buyOperation
                    ? item.buyOperation
                    : item?.sponsorPackageItem?.package?.buyOperations
                        .items?.[0],
                })
              ),
            },
          };
          resolve(nextSponsor);
        })
        .catch((e) => {
          console.error('get-sponsor', e);
          reject();
        })
        .finally(hideLoader);
    });

  const getDeadline = (eventId, type) =>
    new Promise((resolve, reject) => {
      showLoader();

      API.graphql({
        query: getDeadlineQuery,
        variables: { externalId: eventId, type },
      })
        .then((response) => {
          resolve(response.data.getDeadline);
        })
        .catch((e) => {
          console.error('get-deadline', e);
          reject();
        })
        .finally(hideLoader);
    });

  return { update, get, getDeadline };
};
